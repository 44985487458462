import React from 'react';
import './progress.scss';

const CircularProgressBar = ({ size = 1.5, light = false }) => {
  return (
    <progress
      style={{ width: `${size}em`, height: `${size}em` }}
      className={`pure-material-progress-circular ${light ? 'text-white' : ''}`}
    />
  );
};

export default CircularProgressBar;

import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import SignaturePad from './SignaturePad';
import SignatureTemplate from './SignatureTemplate';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { toast } from 'react-toastify';
import trimCanvas from 'trim-canvas';

const Tab = ({ disabled, tabs = [], selectedTab, onTabSelect }) => {
  return (
    <div className="d-flex flex-wrap gap-1">
      {tabs?.map(tab => {
        return (
          <Button
            key={tab}
            className="px-2 py-1"
            size="sm"
            disabled={disabled}
            variant={tab === selectedTab ? 'primary' : 'outline-primary'}
            onClick={() => onTabSelect(tab)}
          >
            <span className="large">{tab}</span>
          </Button>
        );
      })}
    </div>
  );
};

const AddUpdateSignatureModal = ({ show, onHide, editMode, user, showProgress, onSubmit }) => {
  const [drawingMode, setDrawingMode] = useState('Draw');
  const [signature, setSignature] = useState(null);
  const [uploadingSignature, setUploadingSignature] = useState(false);
  const sigPad = useRef();
  const [text, setText] = useState('');
  const [signatures, setSignatures] = useState([]);

  const handleSubmit = async () => {
    if (!signature) return toast.error('Please draw a signature');
    try {
      let trimmedSignature = signature;
      if (drawingMode === 'Draw' && sigPad) {
        const trimmedCanvas = sigPad.current.getCanvas();
        trimmedSignature = trimCanvas(trimmedCanvas).toDataURL('image/png');
      }
      setUploadingSignature(true);
      const requestBody = {
        fileName: 'signature.png',
        mimeType: 'image/png',
        data: trimmedSignature.split(',')[1]
      };
      const { response, error } = await makeApiRequests({
        requestBody,
        endpoint: ENDPOINTS.BASE64_FILE
      });
      if (error) {
        toast.error(error);
      }
      onSubmit(response);
      onHide();
    } catch (error) {
      console.log(error);
    } finally {
      setUploadingSignature(false);
    }
  };

  const onTabSelect = tab => {
    setDrawingMode(tab);
    setSignature(null);
  };

  useEffect(() => {
    setText(user?.name);
  }, [user]);

  return (
    <Modal size={'lg'} show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>
          <h6 className="mb-0">{editMode ? 'Update signature' : 'Add signature'}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <Tab tabs={['Draw', 'Templates']} selectedTab={drawingMode} onTabSelect={onTabSelect} />
        <div className="mt-2 pt-2 border-top">
          {drawingMode === 'Draw' ? (
            <SignaturePad sigPad={sigPad} onChange={setSignature} />
          ) : (
            <SignatureTemplate
              signatures={signatures}
              setSignatures={setSignatures}
              text={text}
              setText={setText}
              onSelectSignature={setSignature}
              selectedSignature={signature}
            />
          )}
        </div>
        {showProgress && <ProgressBar className="my-2" animated now={100} label={'Please wait...'} />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          disabled={uploadingSignature || showProgress}
          className="ml-2 px-2"
          variant={'danger'}
          onClick={onHide}
        >
          Close
        </Button>
        <Button
          disabled={uploadingSignature || showProgress}
          size="sm"
          className="ml-2 px-2"
          variant={'success'}
          onClick={handleSubmit}
        >
          {uploadingSignature || showProgress ? 'Please wait...' : editMode ? 'Update' : 'Add'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUpdateSignatureModal;

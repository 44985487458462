import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Arrow90degLeft, Arrow90degRight, Trash } from 'react-bootstrap-icons';
import SignatureCanvas from 'react-signature-canvas';

const SignaturePad = ({ sigPad, onChange }) => {
  const [penThickness, setPenThickness] = useState(2);
  const [strokes, setStrokes] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const clear = () => {
    sigPad.current.clear();
    setStrokes([]);
    setRedoStack([]);
    if (onChange) onChange(null); // Clear the Base64 output
  };

  const handleThicknessChange = event => {
    setPenThickness(Number(event.target.value));
  };

  const handleEndStroke = () => {
    // When a stroke is completed, save the current strokes
    const data = sigPad.current.toData();
    setStrokes(data);
    setRedoStack([]); // Clear the redo stack after a new stroke

    // Convert to Base64 and trigger onChange
    if (onChange) {
      const dataURL = sigPad.current.toDataURL('image/png');
      onChange(dataURL); // Send Base64 data to the parent component
    }
  };

  const undo = () => {
    if (strokes.length > 0) {
      const newRedoStack = [...redoStack, strokes[strokes.length - 1]];
      const newStrokes = strokes.slice(0, -1); // Remove the last stroke
      setStrokes(newStrokes);
      setRedoStack(newRedoStack);
      sigPad.current.fromData(newStrokes);

      // Trigger onChange with updated Base64
      if (onChange) {
        const dataURL = sigPad.current.toDataURL('image/png');
        onChange(dataURL);
      }
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const newStrokes = [...strokes, redoStack[redoStack.length - 1]];
      const newRedoStack = redoStack.slice(0, -1);
      setStrokes(newStrokes);
      setRedoStack(newRedoStack);
      sigPad.current.fromData(newStrokes);

      // Trigger onChange with updated Base64
      if (onChange) {
        const dataURL = sigPad.current.toDataURL('image/png');
        onChange(dataURL);
      }
    }
  };

  return (
    <div className="d-flex justify-content-between gap-2">
      <SignatureCanvas
        ref={sigPad}
        penColor="black"
        minWidth={penThickness}
        maxWidth={penThickness}
        onEnd={handleEndStroke}
        canvasProps={{
          width: 550,
          height: 300,
          className: 'sigCanvas border'
        }}
      />
      <div>
        {/* <h6 className="fw-bold">Pen Options</h6>
        <h6 className="mb-0">Thickness: {penThickness}</h6>
        <input type="range" min="1" max="10" value={penThickness} onChange={handleThicknessChange} /> */}
        <h6 className="fw-bold">Actions</h6>
        <div className="my-2 d-flex gap-1 align-items-center">
          <Button
            onClick={undo}
            size="sm"
            disabled={strokes.length === 0}
            variant={strokes.length === 0 ? 'outline-dark' : 'dark'}
          >
            <Arrow90degLeft /> Undo
          </Button>
          <Button
            onClick={redo}
            size="sm"
            disabled={redoStack.length === 0}
            variant={redoStack.length === 0 ? 'outline-dark' : 'dark'}
          >
            Redo <Arrow90degRight />
          </Button>{' '}
          <Button onClick={clear} size="sm" variant="danger">
            <Trash /> Clear
          </Button>
        </div>{' '}
      </div>
    </div>
  );
};

export default SignaturePad;

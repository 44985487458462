import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Container, Modal, ProgressBar } from 'react-bootstrap';
import { ChevronDown, ChevronUp, Pen, PlusCircle, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { makeApiRequests } from '../../helpers/api';
import AlertModal from '../AlertModal';
import Loader from '../Loader';
import EntityTable from './EntityTable.js';
import { sortEntityItemsAccordingToPositions } from '../../helpers/global';

const getForm = ({ entityName, rows, type = 'Add' }) => ({
  forms: [
    {
      name: `${type} ${entityName.toLowerCase()}`,
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: type,
        show: true,
        onSubmit: type === 'Add' ? 'onNewItemFormSubmit' : 'onUpdateItemFormSubmit'
      },
      rows
    }
  ]
});

const AddEditModal = ({
  show,
  toBeEditedItem,
  onHide,
  showProgress,
  addForm,
  updateForm,
  entityName,
  secondaryUpdateRows
}) => {
  // To Be Improved

  updateForm = useMemo(() => {
    if (secondaryUpdateRows && toBeEditedItem?.name === 'Featured Videos') {
      return getForm({ entityName, rows: secondaryUpdateRows, type: 'Update' });
    }
    return updateForm;
  }, [toBeEditedItem]);

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header closeButton={!showProgress}>
          <Modal.Title>
            <h6 className="mb-0">{toBeEditedItem ? `Update ${entityName}` : `Add New ${entityName}`}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto px-1">
          <FormGenerator
            formJson={toBeEditedItem ? updateForm : addForm}
            formValues={toBeEditedItem ? { [`Update ${entityName.toLowerCase()}`]: toBeEditedItem } : {}}
          />
          {showProgress && (
            <ProgressBar
              now={100}
              animated
              striped
              label={toBeEditedItem ? `Updating ${entityName}...` : `Saving ${entityName}....`}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

/**
 *  example: 
 *  entityName: Manufacturer,
 *  entityKey: manufacturer,
    endpoint: /manufacturers
    formFields: 
 */
const EntityCRUD = ({
  entityName,
  endpoint,
  entityTableHeaders,
  entityTableSearchKeys,
  addFormRows = [],
  updateFormRows = [],
  onProgressChange,
  onEntityUpdate,
  entityAppChoiceKey,
  addNewButton = true,
  deleteButton = true,
  secondaryUpdateRows,
  groupBy,
  groupValues,
  showAdditionalHeader,
  draggable = false,
  onDragEndpoint
}) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState();
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [toBeDeletedItem, setToBeDeletedItem] = useState(null);
  const [itemActionMeta, setItemActionMeta] = useState(null);
  const [itemAddingUpdating, setItemAddingUpdating] = useState(false);
  const [showGroupChildren, setShowGroupChildren] = useState({});

  const onListSort = async data => {
    if (draggable) {
      const { response, error } = await makeApiRequests({
        endpoint: `${onDragEndpoint}`,
        method: 'POST',
        requestBody: data.map((fd, index) => ({
          _id: fd?._id,
          position: index,
          [groupBy]: fd?.[groupBy]
        }))
      });
    }
  };

  const fetchItems = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: `${endpoint}/list`,
      method: 'POST'
    });

    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setItems(sortEntityItemsAccordingToPositions(response));
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (!items) return;
    //update localstorage
    const oldAppChoicesString = localStorage.getItem('app-choices');
    let appChoices = [];
    try {
      appChoices = JSON.parse(oldAppChoicesString);
    } catch (e) {}

    const oldAppChoiceIndex = appChoices.findIndex(ac => ac.key === entityAppChoiceKey);
    if (oldAppChoiceIndex === -1) {
      appChoices.push({ key: entityAppChoiceKey, values: items });
    } else {
      appChoices[oldAppChoiceIndex] = { key: entityAppChoiceKey, values: items };
    }

    localStorage.setItem('app-choices', JSON.stringify(appChoices));
  }, [items]);

  useEffect(() => {
    onProgressChange && onProgressChange(deleteInProgress || itemAddingUpdating);
  }, [deleteInProgress, itemAddingUpdating]);

  const deleteItem = async () => {
    setDeleteInProgress(true);

    const { response, error } = await makeApiRequests({
      endpoint: `${endpoint}/${toBeDeletedItem._id}`,
      method: 'DELETE'
    });

    setDeleteInProgress(false);
    if (error) {
      toast.error(error);
      return;
    }

    items.splice(items.findIndex(i => i._id === toBeDeletedItem._id), 1);
    setItems([...items]);
    onEntityUpdate && onEntityUpdate(entityAppChoiceKey, entityName, items);
    setToBeDeletedItem(null);
  };

  const onNewItemFormSubmit = async form => {
    setItemAddingUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: endpoint,
      requestBody: form
    });

    setItemAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    const newItems = [response, ...items];
    setItems(newItems);
    onEntityUpdate && onEntityUpdate(entityAppChoiceKey, entityName, newItems);
    setItemActionMeta(null);
  };

  const onUpdateItemFormSubmit = async form => {
    setItemAddingUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: `${endpoint}/${itemActionMeta.item._id}`,
      requestBody: { ...form },
      method: 'PUT'
    });

    setItemAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    const itemIndex = items.findIndex(i => i._id === response._id);
    items[itemIndex] = { ...items[itemIndex], ...response };
    setItems([...items]);
    onEntityUpdate && onEntityUpdate(entityAppChoiceKey, entityName, items);
    setItemActionMeta(null);
  };

  const onShowGroupChildrenChange = (key, value) => {
    showGroupChildren[key] = value;
    setShowGroupChildren({ ...showGroupChildren });
  };

  window['onNewItemFormSubmit'] = onNewItemFormSubmit;
  window['onUpdateItemFormSubmit'] = onUpdateItemFormSubmit;

  useEffect(() => {
    if (groupValues) {
      groupValues.forEach(groupValue => {
        showGroupChildren[groupValue] = true;
      });
      showGroupChildren['Others'] = true;
    }
  }, [groupValues]);

  return (
    <Container fluid className="h-100 py-3 px-md-3">
      <Card>
        <Card.Body>
          <h6>{entityName}</h6>
          <hr className="my-3" />
          {loading ? (
            <Loader />
          ) : (
            items && (
              <>
                <EntityTable
                  rowKeyField={'_id'}
                  data={items}
                  draggable={draggable}
                  headers={entityTableHeaders}
                  searchQueryKeys={entityTableSearchKeys}
                  groupBy={groupBy}
                  groupValues={groupValues}
                  toggleShowMoreSection={showGroupChildren}
                  onListSort={draggable && onListSort}
                  additionalHeaderComponentForGroupValues={
                    showAdditionalHeader
                      ? currentGroupValue => {
                          return (
                            <>
                              <div className="w-100 d-flex justify-content-between align-items-center">
                                <div className="d-flex justify-content-start align-items-center">
                                  <h6 className="midFont my-0">{currentGroupValue || 'Others'}</h6>
                                  {!showGroupChildren[currentGroupValue] ? (
                                    <ChevronUp
                                      className="ml-2 pointer mb-1"
                                      title="Show Less"
                                      onClick={() => onShowGroupChildrenChange(currentGroupValue, true)}
                                      size={17}
                                    />
                                  ) : (
                                    <ChevronDown
                                      className="ml-2 pointer"
                                      title="Show More"
                                      onClick={() => onShowGroupChildrenChange(currentGroupValue, false)}
                                      size={17}
                                    />
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        }
                      : null
                  }
                  additionalComponents={() => {
                    return (
                      <>
                        {addNewButton && (
                          <Button size="sm" variant="success" onClick={() => setItemActionMeta({ mode: 'add' })}>
                            <PlusCircle className="mr-1" /> New {entityName}
                          </Button>
                        )}
                      </>
                    );
                  }}
                  actionCell={item => (
                    <div>
                      <Button
                        variant="outline-primary"
                        className="px-1 py-0 mr-1"
                        onClick={() => setItemActionMeta({ item, mode: 'edit' })}
                      >
                        <Pen size={10} />
                      </Button>
                      {deleteButton && (
                        <Button
                          variant="outline-danger"
                          className="px-1 py-0 "
                          onClick={() => setToBeDeletedItem(item)}
                        >
                          <Trash size={10} />
                        </Button>
                      )}
                    </div>
                  )}
                />
                {items.length > 0 && (
                  <AlertModal
                    show={toBeDeletedItem !== null}
                    alertText={`Are you sure you want to delete this ${entityName.toLowerCase()}? This action cannot be undone!`}
                    onHide={() => setToBeDeletedItem(null)}
                    onDismissClick={() => setToBeDeletedItem(null)}
                    onContinueClick={deleteItem}
                    progressText={`Deleting ${entityName.toLowerCase()}...`}
                    showProgress={deleteInProgress}
                  />
                )}
                <AddEditModal
                  entityName={entityName}
                  show={itemActionMeta !== null}
                  onHide={() => setItemActionMeta(null)}
                  toBeEditedItem={itemActionMeta ? itemActionMeta.item : null}
                  showProgress={itemAddingUpdating}
                  addForm={getForm({ entityName, rows: addFormRows })}
                  updateForm={getForm({ entityName, rows: updateFormRows, type: 'Update' })}
                  secondaryUpdateRows={secondaryUpdateRows}
                />
              </>
            )
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EntityCRUD;

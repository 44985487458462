import React, { useCallback, useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Pencil, Plus, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { deleteFileFromServer, readFile } from '../../helpers/api';
import AlertModal from '../AlertModal';
import Loader from '../Loader';
import AddUpdateSignatureModal from './AddUpdateSignatureModal';

const Signature = ({ signature = null, user, updateSignature }) => {
  const [loadingSignature, setLoadingSignature] = useState(false);
  const [signatureData, setSignatureData] = useState(null);
  const [addUpdateSignatureMeta, setAddUpdateSignatureMeta] = useState(null);
  const [deleteSignatureMeta, setDeleteSignatureMeta] = useState(null);

  useEffect(() => {
    if (signature?.filename) {
      getSignature(signature.filename);
    } else {
      setSignatureData(null);
    }
  }, [signature]);

  const onDeleteClick = () => {
    setDeleteSignatureMeta({ signature });
  };

  const deleteSignature = async () => {
    try {
      setDeleteSignatureMeta({ ...deleteSignatureMeta, showProgress: true });
      await updateSignature(null);
      setDeleteSignatureMeta(null);
      deleteFileFromServer(signature);
    } catch (error) {
      console.log(error);
    }
  };

  const getSignature = async fileName => {
    setLoadingSignature(true);
    const { response, error } = await readFile({ fileName });
    setLoadingSignature(false);
    if (error) {
      toast.error(error);
    }
    setSignatureData(response);
  };

  const onAddEditSignatureClick = useCallback(mode => {
    setAddUpdateSignatureMeta({ mode });
  }, []);

  return (
    <div className="py-2">
      {loadingSignature ? (
        <Loader />
      ) : signatureData ? (
        <div className="d-flex gap-2">
          <Image src={signatureData} alt={`Signature`} className="border" />
          <div className="d-flex flex-column gap-2">
            <Button size="sm" variant="outline-success" className="" onClick={() => onAddEditSignatureClick('update')}>
              <Pencil /> Edit
            </Button>{' '}
            <Button size="sm" variant="outline-danger" className="" onClick={onDeleteClick}>
              <Trash /> Delete
            </Button>
          </div>
        </div>
      ) : (
        <Button size="sm" variant="outline-success" onClick={() => onAddEditSignatureClick('add')}>
          <Plus /> Create new signature
        </Button>
      )}

      <AddUpdateSignatureModal
        show={Boolean(addUpdateSignatureMeta)}
        onHide={() => setAddUpdateSignatureMeta(null)}
        showProgress={addUpdateSignatureMeta?.showProgress}
        editMode={addUpdateSignatureMeta?.mode === 'update'}
        onSubmit={updateSignature}
        user={user}
      />

      <AlertModal
        show={Boolean(deleteSignatureMeta)}
        onHide={() => setDeleteSignatureMeta(null)}
        showProgress={deleteSignatureMeta?.showProgress}
        onContinueClick={deleteSignature}
        onDismissClick={() => setDeleteSignatureMeta(null)}
        alertText={'Are you sure you want to delete your signature?'}
      />
    </div>
  );
};

export default Signature;

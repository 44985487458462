import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { Map } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { siteHeaders } from '../admin/constants';
import SiteMapPopup from '../admin/site-map/SiteMapPopup';
import EntityTable from '../common/EntityTable';
import Loader from '../Loader';

export default function SiteMap() {
  const [loading, setLoading] = useState(false);
  const [siteMapMeta, setSiteMapMeta] = useState(null);
  const [sites, setSites] = useState([]);
  const [siteActionMeta, setSiteActionMeta] = useState(null);

  const fetchSites = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SITE_LIST,
      method: 'POST',
      requestBody: {
        populate: true,
        filter: {},
        sort: { salesLocation: 1 }
      }
    });

    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setSites(response.results);
  };

  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    if (siteMapMeta) {
      setSiteMapMeta({ ...siteMapMeta, site: sites.find(s => s._id === siteMapMeta.site._id) });
    }
  }, [sites]);

  const siteTableSearchKeys = ['name'];

  const onSiteUpdate = site => {
    const existingIndex = sites.findIndex(s => s._id === site._id);
    if (existingIndex !== -1) {
      sites[existingIndex] = site;
    } else {
      sites.push(site);
    }
    setSites([...sites]);
  };

  return (
    <Container fluid className="h-100 py-3 px-md-3">
      <Card>
        <Card.Body>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h6 className="mb-0">All Sites ({sites.length})</h6>
            </div>
          </div>

          <hr className="my-1" />
          {loading ? (
            <Loader />
          ) : (
            <>
              <EntityTable
                rowKeyField={'_id'}
                data={sites}
                quickSearch={false}
                headers={[{ name: 'Sales Location', key: 'salesLocationName' }, ...siteHeaders]}
                searchQueryKeys={siteTableSearchKeys}
                actionCell={site => (
                  <div>
                    <Button
                      variant="outline-primary"
                      className="px-2 py-0 mr-1"
                      onClick={() => setSiteMapMeta({ site: sites.find(s => s._id === site._id) })}
                    >
                      <Map size={12} /> Open Site Map
                    </Button>
                  </div>
                )}
              />
              <SiteMapPopup
                show={Boolean(siteMapMeta)}
                site={siteMapMeta?.site}
                onSiteUpdate={onSiteUpdate}
                onHide={() => setSiteMapMeta(null)}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

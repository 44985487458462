import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import SiteMap from './SiteMap';

const SiteMapPopup = ({ site, show, onHide, onSiteUpdate }) => {
  const occupiedSlot = useMemo(() => {
    return site?.slots?.filter(item => item?.inventory !== null).length;
  }, [site]);

  return (
    <Modal className="fullscreen-modal" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header className="p-2" closeButton>
        <Modal.Title className="w-50">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h6 className="mb-0">Site Map ({site?.name})</h6>
            <div className="ml-auto">
              <h6 className="mb-0 mid">
                <b>{occupiedSlot}</b> of <b>{site?.slots?.length}</b> Slots Occupied
              </h6>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">{site && <SiteMap site={site} onSiteUpdate={onSiteUpdate} />}</Modal.Body>
    </Modal>
  );
};

export default SiteMapPopup;

import { normalizeId } from '../form-generator/helpers/utility';
import { contractMilestoneDates, isMicasa, commissionImportantFieldHTML, salesImportantFieldHTML } from './constants';
import { addChoicesToCRMForm } from '../components/crm/form';
import {
  addChoicesToContractForm,
  additionalFieldsRow as contractAdditionalFields,
  mainFieldsRows as contractMainFieldRows,
  typeOfTransactionFields,
  validateAndCleanupForm
} from '../components/main/form';
import { getAdditionalFieldsColumns } from './formHelpers';
import { getProfileFromLocalStorage } from './session';

export const camelCaseToTitle = (camelCaseText = '') => {
  if (!camelCaseText) return '';

  const result = camelCaseText.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const createTextField = ({ key }) => {
  return {
    default: 6,
    xs: 12,
    field: {
      id: key,
      key,
      title: camelCaseToTitle(key),
      type: 'text',
      required: false
    }
  };
};

const titleRow = (title, className = '', withHr = true) => ({
  columns: [
    {
      default: 12,
      field: {
        type: 'custom-html',
        html: `<h6 class="mt-2 mb-0 text-muted ${className}"><b>${title}</b></h6>${
          withHr ? "<hr class='mt-1 mb-2'/>" : ''
        }`
      }
    }
  ]
});

const fieldJsonToColumns = (field, summaryFieldType) => {
  if (field.key === 'typeOfTransaction') {
    return typeOfTransactionFields(true);
  }
  if (field.key === 'signature') {
    const user = getProfileFromLocalStorage();
    return {
      default: 6,
      field: {
        id: field.key,
        required: false,
        title: 'Use signature of',
        type: 'dropdown',
        options: [],
        value: user?._id
      }
    };
  }
  if (field.key === 'mailingZip' || field.key === 'zipCode') {
    return {
      default: 6,
      field: {
        id: field.key,
        onTextChange: field.key === 'mailingZip' ? 'autoFillMailingAddress' : 'autoFillpropertyAddress',
        required: false,
        title: field.key === 'mailingZip' ? 'Mailing Zip' : 'Property Zip',
        type: 'text'
      }
    };
  }
  const searchFields = [...contractMainFieldRows().flatMap(r => r.columns), ...contractAdditionalFields.columns];
  //if is date field, return all related fields
  const contractMilestoneDate = contractMilestoneDates.find(d => d.key === field.key);
  if (contractMilestoneDate) {
    const relatedFieldKeys = ['start', 'end', 'emailField', 'options'];
    return relatedFieldKeys
      .map(k =>
        searchFields.find(
          searchField =>
            searchField.field.key === contractMilestoneDate[k] || searchField.field.id === contractMilestoneDate[k]
        )
      )
      .filter(field => Boolean(field));
  }

  const filteredColumn =
    searchFields.find(searchField => searchField.field.key === field.key || searchField.field.id === field.key) ||
    createTextField(field);

  const filteredField = filteredColumn.field;

  const returnColumn = {
    default: filteredField.type === 'key-value' ? 12 : 6,
    field: filteredField
  };

  if (summaryFieldType) {
    let onChange;

    if (summaryFieldType === 'Sales Agreement' && calculateSalesAgreementFields.includes(field.key)) {
      onChange = 'calculateSalesSummary';
    } else if (calculateCommissionSheetFields.includes(field.key)) {
      onChange = 'calculateCommissionsSummary';
    }
    returnColumn['field'][['text', 'number'].includes(filteredField.type) ? 'onTextChange' : 'onChange'] = onChange;
  }

  return [returnColumn];
};

export const getDocHubFormJSON = (
  { withData = [], withoutData = [], new: newFields = [] },
  summaryFieldType,
  appChoices,
  docSource
) => {
  const withDataColumns = withData
    .filter(f => !['_id', 'additionalFields', 'signature'].includes(f.name))
    .flatMap(f => fieldJsonToColumns({ key: f.name, value: f.value }, summaryFieldType));

  const withoutDataColumns = withoutData
    .filter(f => !['_id', 'additionalFields', 'signature'].includes(f.name))
    .flatMap(f => fieldJsonToColumns({ key: f.name }, summaryFieldType));

  const newFieldsColumns = newFields
    .filter(f => !['_id', 'additionalFields', 'signature'].includes(f))
    .flatMap(f => fieldJsonToColumns({ key: f }, summaryFieldType));

  const rows = [];

  if (withDataColumns.length > 0) {
    rows.push(titleRow(`Existing Fields from ${docSource}`), { columns: withDataColumns });
  }
  if (withoutDataColumns.length > 0) {
    rows.push(titleRow(`Empty Fields from ${docSource}`), { columns: withoutDataColumns });
  }
  if (newFieldsColumns.length > 0) {
    rows.push(titleRow('New Fields'), { columns: newFieldsColumns });
  }

  const additionalFieldsInWithData = withData.find(c => c.name === 'additionalFields');
  const additionalFieldsInWithOutData = withoutData.find(c => c.name === 'additionalFields');
  const additionalFieldsToUse = additionalFieldsInWithData || additionalFieldsInWithOutData;

  if (additionalFieldsToUse) {
    const additionalFieldKeysInDoc = additionalFieldsToUse.pathsInDoc.map(p => p[p.length - 1]);

    const additionalFieldColumns = getAdditionalFieldsColumns({
      appChoices,
      model: docSource,
      keysToUse: additionalFieldKeysInDoc
    });

    if (additionalFieldColumns.length > 0) {
      rows.push(titleRow('Additional DocHub Fields'), {
        columns: additionalFieldColumns.map(c => {
          let onChange;
          if (summaryFieldType) {
            if (summaryFieldType === 'Sales Agreement' && calculateSalesAgreementFields.includes(c.field.key)) {
              onChange = 'calculateSalesSummary';
            } else if (calculateCommissionSheetFields.includes(c.field.key)) {
              onChange = 'calculateCommissionsSummary';
            }
          }

          return {
            ...c,
            field: {
              ...c.field,
              [['text', 'number'].includes(c.field.type) ? 'onTextChange' : 'onChange']: onChange
            }
          };
        })
      });
    }
  }

  const signatureField = withoutData.find(c => c.name === 'signature');
  if (signatureField) {
    rows.push(
      titleRow('Signature (Create a signature from your profile <a href="/profile" target="_blank">here</a>)'),
      {
        columns: [fieldJsonToColumns({ key: 'signature' })]
      }
    );
  }

  if (rows.length === 0) {
    rows.push(titleRow('No parameters to fill!', 'text-muted text-center p-4', false));
  }

  const documentFormJSON = {
    forms: [
      {
        name: 'document',
        hideFormName: true,
        markCompulsoryFields: true,
        compact: true,
        submit: {
          name: 'Print',
          show: false,
          onSubmit: 'onPrintSubmit'
        },
        rows: [
          ...rows,
          ...(summaryFieldType
            ? [
                {
                  columns: [
                    {
                      default: 12,
                      field: {
                        type: 'custom-html',
                        html:
                          summaryFieldType === 'Commission Sheet'
                            ? commissionImportantFieldHTML
                            : salesImportantFieldHTML
                      }
                    }
                  ]
                }
              ]
            : [])
        ]
      }
    ]
  };

  addChoicesToDochubForm(documentFormJSON, appChoices);

  return documentFormJSON;
};

export const calculateCommissionsSummary = (inventoryInvoice = 0) => {
  const salesPrice = Number(document.getElementById('salesPrice').value || 0);

  const commissionLessValue = getTabularOptionsFieldValue('commissionSheetLessOptions');
  const netCommissionsTotal = salesPrice - commissionLessValue;

  const optionsTotal = inventoryInvoice + getTabularOptionsFieldValue('commissionSheetOptions');

  const grossProfit = netCommissionsTotal - optionsTotal;
  let commissionDue;

  let bonus = 0;
  const bonusType = document.getElementById('bonusType-additionalDochubField')?.value || 'Bonus';

  if (isMicasa) {
    bonus = Number(document.getElementById('bonus-additionalDochubField')?.value || 0);
    commissionDue = grossProfit >= 20000 ? 0.25 * grossProfit : 0.2 * grossProfit;
  } else {
    commissionDue = grossProfit / 2;
  }

  const totalWithBonus = commissionDue + bonus;
  const frontEnd = totalWithBonus / 2;

  document.getElementById('netCommission').innerHTML = `$${netCommissionsTotal.toFixed(2)}`;
  document.getElementById('totalOfOptions').innerHTML = `$${optionsTotal.toFixed(2)}`;
  document.getElementById('grossProfit').innerHTML = `$${grossProfit.toFixed(2)}`;
  document.getElementById('commissionDue').innerHTML = `$${commissionDue.toFixed(2)}`;
  document.getElementById('frontEnd').innerHTML = `$${frontEnd.toFixed(2)}`;
  document.getElementById('backEnd').innerHTML = `$${frontEnd.toFixed(2)}`;

  if (document.getElementById('bonusTypeName')) {
    document.getElementById('bonusTypeName').innerHTML = bonusType;
    document.getElementById('bonusValue').innerHTML = `$${bonus.toFixed(2)}`;
    document.getElementById('totalWithBonusValue').innerHTML = `$${totalWithBonus.toFixed(2)}`;
  }
};

export const calculateSalesSummary = () => {
  //TODO this is now a table
  const optionalValues = getTabularOptionsFieldValue('optionalEquipmentAndAccessories');

  const salesPrice = Number(document.getElementById('salesPrice').value);
  //sales price + options
  const subTotal = optionalValues + salesPrice;

  //Trade-In Gross Allowance - Less Balance Due
  const tradeInGrossAllowance = Number(document.getElementById('tradeInGrossAllowance').value);
  const lessBalanceDue = Number(document.getElementById('lessBalanceDue').value);
  const tradeNetAllowance = tradeInGrossAllowance - lessBalanceDue;

  //Trade-In Net Allowance + Cash As Agreed	+ Down Payment
  const cashAsAgreed = Number(document.getElementById('cashAsAgreed').value);
  const downPayment = Number(document.getElementById('downPayment').value);
  const totalDownPayment = tradeNetAllowance + cashAsAgreed + downPayment;

  // subtotal - total downpayment
  const unpaidCashPrice = subTotal - totalDownPayment;

  //total unpaid balance = unpaid cash + unpaid fields
  const totalUnpaidBalance =
    unpaidCashPrice +
    unpaidFields
      .map(f => {
        try {
          return Number(document.getElementById(f).value);
        } catch (error) {
          return 0;
        }
      })
      .reduce((prevValue, newValue) => {
        return prevValue + newValue;
      });

  //totalunpaid - Origination Points
  const originationPoints = Number(document.getElementById('originationPoints').value);
  const totalBalanceDue = totalUnpaidBalance - originationPoints;

  document.getElementById('options').innerHTML = `$${optionalValues.toFixed(2)}`;
  document.getElementById('subTotal').innerHTML = `$${subTotal.toFixed(2)}`;
  document.getElementById('tradeInNetAllowance').innerHTML = `$${tradeNetAllowance.toFixed(2)}`;
  document.getElementById('totalDownPayment').innerHTML = `$${totalDownPayment.toFixed(2)}`;
  document.getElementById('unpaidCashPrice').innerHTML = `$${unpaidCashPrice.toFixed(2)}`;
  document.getElementById('totalUnpaidBalance').innerHTML = `$${totalUnpaidBalance.toFixed(2)}`;
  document.getElementById('totalBalanceDue').innerHTML = `$${totalBalanceDue.toFixed(2)}`;
};

export const getKeyValueFieldsValue = inputId => {
  try {
    const keyValueContainer = document.getElementById(inputId);

    const checkedKeys = [...keyValueContainer.getElementsByTagName('input')].filter(
      input => input.type === 'checkbox' && input.checked
    );

    const values = checkedKeys
      .map(input => ({ checked: input.checked, value: input.value }))
      .map(key => {
        try {
          return Number(document.getElementById(`${inputId}-${normalizeId(key.value)}-key-value`).value);
        } catch (error) {
          return 0;
        }
      });

    return values.length > 0 ? values.reduce((prevValue, newValue) => prevValue + newValue) : 0;
  } catch (e) {
    return 0;
  }
};

export const getTabularOptionsFieldValue = inputId => {
  try {
    const table = document.getElementById(inputId);
    var tbodyRowCount = table.tBodies[0]?.rows.length || 0;

    let value = 0;
    const normalizedId = normalizeId(inputId);
    for (let index = 0; index < tbodyRowCount; index++) {
      const valueInput = Number(document.getElementById(`${normalizedId}-value-${index}`).value);
      value = value + (isNaN(valueInput) ? 0 : valueInput);
    }

    return value;
  } catch (e) {
    return 0;
  }
};

const addChoicesToDochubForm = (form, appChoices) => {
  addChoicesToContractForm({ form, appChoices, ignoreTableFields: true });
  addChoicesToCRMForm({ form, appChoices, ignoreTableFields: true });
};

export const netCommissionsFieldKeys = ['adminCosts', 'formTCosts', 'mhitCosts', 'titleFeesCosts'];
export const optionsFieldKeys = [
  'acquisitionCosts',
  'adminCosts',
  'stepsCosts',
  'deliveryCosts',
  'newAcCosts',
  'skirtingCosts',
  'floorplanCosts',
  'mileageCosts',
  'breakdownCosts',
  'improvementCosts',
  'calicheBasePadCosts',
  'houseCatCosts',
  'hookupCosts',
  'tntCosts',
  'otherCosts'
];

export const commissionSheetFieldKeys = [
  'buyer',
  'lender',
  'consultant',
  'typeOfUnit',
  'address',
  'city',
  'state',
  'zip',
  'cell#',
  'salesCenter',
  'deliveryDate',
  'fundedDate',
  ...netCommissionsFieldKeys,
  ...optionsFieldKeys
];

export const unpaidFields = [
  'floodCertificationCosts',
  'appraisalCosts',
  'improvementCosts',
  'insuranceCosts',
  'formTCosts',
  'titleFeesCosts',
  'taxFactorAdjustedSalesPrice',
  'otherCosts',
  'originationPoints'
];

const unitInsulationFields = [
  'ceilingRValues',
  'ceilingThickness',
  'wallsRValues',
  'wallsThickness',
  'floorRValues',
  'floorThickness'
];

const tradeInFields = ['tradeInGrossAllowance', 'lessBalanceDue', 'cashAsAgreed', 'downPayment'];

export const calculateSalesAgreementFields = [
  'salesPrice',
  ...unitInsulationFields,
  'optionalEquipmentAndAccessories',
  ...tradeInFields,
  ...unpaidFields,
  'closingDocPrepFedexNotary'
];

export const calculateCommissionSheetFields = [
  'salesPrice',
  'commissionSheetOptions',
  'commissionSheetLessOptions',
  'bonusType-additionalDochubField',
  'bonus-additionalDochubField'
];

export const salesAgreementSheetFields = [
  'salesLocation',
  'lender',
  'applicationNumber',
  'buyer',
  'buyerEmail',
  'consultant',
  'coBuyer',
  'coBuyerName',
  'coBuyerEmail',
  'dateSigned',
  'address',
  'cellPhone',
  'cellPhone2',
  'city',
  'state',
  'zip',
  'size',
  'county',
  'propertyAddress',
  'propertyCity',
  'propertyState',
  'zipCode',
  'newOrUsed',
  'areaSqft)',
  'typeOfUnit',
  'stockOrRSO',
  ...calculateSalesAgreementFields
];

export const validateAndCleanupDocHubForm = form => {
  const contractValid = validateAndCleanupForm(form);

  return contractValid;
};

import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';

const CategorizedDropdown = ({
  activeOptionToView,
  activeOption,
  options,
  toBeActiveKey,
  parentKey,
  childKeys,
  onOptionChange,
  addQuickSearch = true
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const findChildKey = useCallback(
    option => {
      if (!Array.isArray(childKeys)) {
        childKeys = [childKeys];
      }
      const foundKey = childKeys.find(childKey => Object.keys(option).includes(childKey));

      return foundKey ? option[foundKey] : '';
    },
    [childKeys]
  );

  const filteredOptions = useMemo(() => {
    if (!Array.isArray(childKeys)) {
      childKeys = [childKeys];
    }

    return options.filter(option => {
      if (searchQuery.trim() === '') {
        return true;
      }
      if (option.isLabel || !childKeys.some(key => option.hasOwnProperty(key))) {
        return true;
      }
      return childKeys.some(childKey => {
        if (option[childKey]) {
          return option[childKey].toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      });
    });
  }, [options, childKeys, searchQuery]);

  return (
    <>
      <Dropdown onClick={e => e.stopPropagation()}>
        <Dropdown.Toggle block className="p-1" style={{ border: 0, fontSize: 12 }} size="sm">
          {activeOptionToView}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {addQuickSearch && (
            <div className="px-2 pb-2">
              <FormControl
                size="sm"
                placeholder="Search..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </div>
          )}
          {filteredOptions.map(option => (
            <Dropdown.Item
              active={option?.[toBeActiveKey] === activeOption}
              disabled={option?.[parentKey]}
              className={`smallFont py-1 ${option?.[parentKey] ? 'bg-primary-light text-dark px-2' : ''}`}
              key={option._id}
              onClick={() => {
                onOptionChange(option);
              }}
            >
              {option?.[parentKey] && <b>{option?.[parentKey]}</b>}
              <div>{findChildKey(option)}</div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CategorizedDropdown;

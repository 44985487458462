import { toast } from 'react-toastify';
import { highlightError, setAttribute } from '../../form-generator/helpers/utility';
import {
  APP_ROLES,
  CONTRACTOR_CATEGORY_OPTIONS,
  additionalDocHubfieldTypesWithDropdowns,
  commissionImportantFieldHTML,
  contractMilestoneDates,
  typeOfTransactionOptions
} from '../../helpers/constants';
import { convertFormToKeyValueObject } from '../../helpers/formHelpers';
import { sectionCol } from '../../helpers/forms';
import { getProfileFromLocalStorage } from '../../helpers/session';

const appChoices = JSON.parse(localStorage.getItem('app-choices'));

const instructionAppChoices =
  (appChoices && appChoices.find(appChoice => appChoice?.key === 'Special Instruction Options')?.values) || [];

export const typeOfTransactionFields = (fromDocHub = false) => [
  {
    default: 12,
    field: {
      id: 'typeOfTransaction',
      options: typeOfTransactionOptions,
      required: fromDocHub,
      title: 'Type Of Transaction',
      type: 'dropdown',
      dependentElems: ['otherType'],
      showDependentOnArray: ['Personal | Other', 'Real | Other']
    }
  },
  {
    default: 12,
    field: {
      id: 'otherType',
      hint: 'Other type...',
      type: 'text'
    }
  }
];

export const mainFieldsRows = editMode => [
  {
    columns: [
      {
        default: 6,
        xs: 12,
        className: 'align-self-center',
        field: {
          type: 'custom-html',
          html: `<h3 class="mb-0"><b>${editMode ? 'Update' : 'Add New'} Contract</b></h3><hr class="d-md-none"/>`
        }
      },
      {
        default: 6,
        xs: 12,
        className: 'bg-primary text-white rounded p-2',
        field: {
          id: 'status',
          key: 'status',
          title: 'Status',
          type: 'dropdown',
          required: true,
          options: [],
          onChange: 'updateDateSetup'
        }
      },
      {
        default: 12,
        field: {
          type: 'custom-html',
          html: '<hr class="my-1"/>'
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'buyer',
          key: 'buyer',
          title: 'Buyer',
          type: 'text',
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'coBuyer',
          key: 'coBuyer',
          title: 'Co-Buyer',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'salesLocation',
          title: 'Sales Location',
          type: 'dropdown',
          required: true
        }
      }
    ]
  },
  {
    collapsable: true,
    defaultCollapsed: true,
    rowName: 'Additional Fields',
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'dateSetUpStart',
          title: 'Setup Start Date',
          type: 'text',
          variant: 'datetime-local',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'dateSetUpEnd',
          title: 'Setup End Date',
          type: 'text',
          variant: 'datetime-local',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'dateSetupEmails',
          title: 'Notify Date Set Up to:',
          type: 'multiselect-dropdown',
          required: false,
          options: []
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'setupNotificationOptions',
          title: 'Setup Notification Options:',
          type: 'multiselect-dropdown',
          required: false,
          options: [],
          groupBy: 'role'
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'makereadyStartDate',
          title: 'Makeready Start Date',
          variant: 'datetime-local',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'makereadyEndDate',
          title: 'Makeready End Date',
          variant: 'datetime-local',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'makereadyDateEmails',
          title: 'Notify Makeready Date to:',
          type: 'multiselect-dropdown',
          required: false,
          options: [],
          groupBy: 'role'
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'makereadyNotificationOptions',
          title: 'Makeready Notification Options:',
          type: 'multiselect-dropdown',
          required: false,
          options: []
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'walkThroughDate',
          title: 'Walkthrough Date',
          variant: 'date',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'walkThroughDateEmails',
          title: 'Notify Walkthrough Date to:',
          type: 'multiselect-dropdown',
          required: false,
          options: [],
          groupBy: 'role'
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'dateDeliveredStart',
          title: 'Delivery Start Date',
          type: 'text',
          variant: 'datetime-local',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'dateDeliveredEnd',
          title: 'Delivery End Date',
          type: 'text',
          variant: 'datetime-local',
          required: false
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'dateDeliveredEmails',
          title: 'Notify Date Delivered to:',
          type: 'multiselect-dropdown',
          required: false,
          options: [],
          groupBy: 'role'
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'deliveryNotificationOptions',
          title: 'Delivery Notification Options:',
          type: 'multiselect-dropdown',
          required: false,
          options: []
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'serviceStartDate',
          title: 'Service Start Date',
          variant: 'datetime-local',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'serviceEndDate',
          title: 'Service End Date',
          variant: 'datetime-local',
          type: 'text',
          required: false
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'serviceScheduledDateEmails',
          title: 'Notify Service Scheduled Date to:',
          type: 'multiselect-dropdown',
          required: false,
          options: [],
          groupBy: 'role'
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'serviceNotificationOptions',
          title: 'Service Notification Options:',
          type: 'multiselect-dropdown',
          required: false,
          options: []
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'utilities',
          key: 'utilities',
          title: 'Utilities',
          type: 'checkbox-group',
          options: [
            { option: 'Water', value: 'Water' },
            { option: 'Electric', value: 'Electric' },
            { option: 'Septic', value: 'Septic' }
          ],
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'billbackStatus',
          title: 'Bill-Back Status',
          type: 'dropdown',
          required: false,
          options: []
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'tech',
          title: 'Tech',
          key: 'tech',
          type: 'dropdown',
          required: false,
          options: []
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'serviceTech',
          key: 'serviceTech',
          title: 'Service Tech',
          type: 'dropdown',
          required: false,
          options: []
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'partsOrderedDate',
          key: 'partOrderedDate',
          title: 'Parts Ordered Date',
          variant: 'date',
          type: 'text',
          required: false
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'factoryService',
          key: 'factoryService',
          title: 'Factory Service',
          type: 'text',
          required: false
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'salesPerson',
          key: 'salesperson',
          title: 'Salesperson',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'deliveryCompany',
          key: 'deliveryCompany',
          title: 'Delivery Company',
          type: 'text',
          required: false
        }
      },

      {
        default: 4,
        xs: 12,
        field: {
          id: 'cellPhone',
          title: 'Cell Phone #',
          type: 'text',
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'cellPhone2',
          title: 'Cell Phone #2',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'buyerEmail',
          key: 'buyerEmail',
          title: 'Buyer Email',
          type: 'text',
          required: false,
          variant: 'email'
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'coBuyerEmail',
          key: 'coBuyerEmail',
          title: 'Co Buyer Email',
          type: 'text',
          required: false,
          variant: 'email'
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'installer',
          title: 'Installer',
          key: 'installer',
          type: 'dropdown',
          options: [],
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'propertyAddress',
          key: 'propertyAddress',
          title: 'Property Address',
          type: 'text',
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'zipCode',
          key: 'zipCode',
          title: 'Zip Code',
          type: 'text',
          required: true,
          onTextChange: 'autoFillAddress'
        }
      },

      {
        default: 4,
        xs: 12,
        field: {
          id: 'propertyState',
          key: 'propertyState',
          title: 'Property State',
          type: 'text',
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'propertyCity',
          key: 'propertyCity',
          title: 'Property City',
          type: 'text',
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'propertyCounty',
          key: 'propertyCounty',
          title: 'Property County',
          type: 'text',
          required: true
        }
      },

      {
        default: 12,
        field: {
          id: 'sameMailingAddress',
          title: 'Same Mailing Address?',
          defaultChecked: true,
          label: 'Same as Property Address',
          showLabel: true,
          type: 'checkbox',
          required: false,
          dependentElems: ['mailingAddress', 'mailingZip', 'mailingState', 'mailingCity', 'mailingCounty'],
          showDependentOn: false
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'mailingAddress',
          title: 'Mailing Address',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'mailingZip',
          title: 'Mailing Zip',
          type: 'text',
          required: false,
          onTextChange: 'autoFillMailingAddress'
        }
      },

      {
        default: 4,
        xs: 12,
        field: {
          id: 'mailingState',
          title: 'Mailing State',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'mailingCity',
          title: 'Mailing City',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'mailingCounty',
          title: 'Mailing County',
          type: 'text',
          required: false
        }
      },

      {
        default: 12,
        xs: 12,
        field: {
          id: 'stateInspection',
          key: 'stateInspection',
          title: 'State Inspection',
          type: 'radio',
          options: ['No', 'Yes'],
          required: true
        }
      },

      {
        default: 6,
        xs: 12,
        field: {
          id: 'pindropUrl',
          key: 'pindropUrl',
          title: 'Pindrop Url',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'salesPrice',
          title: 'Sales Price',
          type: 'number',
          required: false
        }
      },

      {
        default: 4,
        xs: 12,
        field: {
          id: 'poNum',
          title: 'P.O. #',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'datePORequested',
          title: 'Date P.O Requested',
          type: 'text',
          variant: 'date',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'datePOAuthorized',
          title: 'Date P.O Authorized',
          type: 'text',
          variant: 'date',
          required: false
        }
      },

      {
        default: 12,
        field: {
          type: 'custom-html',
          html: '<hr/>'
        }
      },
      ...(editMode
        ? []
        : [
            {
              default: 12,
              field: {
                id: 'comments',
                type: 'text-area',
                title: 'Comments',
                required: false,
                row: 3
              }
            }
          ])
    ]
  }
];

export const formTFields = [
  {
    key: 'homeInstallationAccordance',
    label: 'Home Installed In Accordance With',
    options: [
      'MANUFACTURERS HOME INSTALLATION INSTRUCTIONS',
      'STATE GENERIC STANDARDS',
      'STABILIZATION SYSTEM REGISTERED',
      'SPECIAL FOUNDATION SYSTEM'
    ]
  },
  { key: 'installedInHumidAndFringeClimate', label: 'Home installed in Humid & Fringe Climate?' },
  { key: 'onlyReleveling', label: 'Only a releveling?' },
  { key: 'labeledForAlternateConstruction', label: 'Home labeled for alternate construction?' },
  { key: 'homeInstalledInFrostlineZone', label: 'Home installed in frostline zone?' },
  { key: 'retailerInstallerProvideSkirting', label: 'Retailer/Installer provides skirting?' },
  { key: 'installationPartOfSalesContract', label: 'Installation part of sales contract of used home?' }
];

const costFields = [
  { key: 'adminCosts', label: 'Admin' },
  { key: 'serviceCosts', label: 'Service' },
  { key: 'npsCosts', label: 'NPS' },
  { key: 'floodCertificationCosts', label: 'Flood Certification' },
  { key: 'appraisalCosts', label: 'Appraisal' },
  { key: 'insuranceCosts', label: 'Insurance' },
  { key: 'improvementCosts', label: 'Improvement' },
  { key: 'formTCosts', label: 'Form T' },
  { key: 'titleFeesCosts', label: 'Title Fees' },
  { key: 'skirtingCosts', label: 'Skirting' },
  { key: 'stepsCosts', label: 'Steps' },
  { key: 'deliveryCosts', label: 'Delivery/Setup' },
  { key: 'newAcCosts', label: 'Air Conditioner' },
  { key: 'trimoutCosts', label: 'Trim Out' },
  { key: 'calicheBasePadCosts', label: 'Caliche Pad' },
  { key: 'lightbulbCosts', label: 'Light Bulbs' },
  { key: 'decksCosts', label: 'Decks' },
  { key: 'hookupCosts', label: 'Hookups' },
  { key: 'utilitiesCosts', label: 'Utilities' },
  { key: 'constructionCosts', label: 'Construction' },
  { key: 'closingCostsAndPrepaids', label: 'Closing Costs and Pre-paids' },
  { key: 'titlePolicyAndSurveyCosts', label: 'Title Policy and Survey' },
  { key: 'otherCosts', label: 'Other' }
].sort((a, b) => (a.label === 'Other' || b.label === 'Other' ? 1 : a.label.localeCompare(b.label)));

export const additionalFieldsRow = {
  collapsable: true,
  defaultCollapsed: true,
  rowName: 'Additional Fields',
  columns: [
    {
      default: 12,
      field: {
        type: 'custom-html',
        html: '<h6 class="mt-2 mb-0 "><b>Additional Fields</b></h6><hr class=\'mb-1\'/>'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateOfDeposit',
        title: 'Date of Deposit',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'newOrUsed',
        title: 'New / Used',
        type: 'dropdown',
        options: ['New', 'Used'],
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'techStatus',
        key: 'techStatus',
        title: 'Tech Status',
        type: 'dropdown',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateFormTSent',
        key: 'dateFormTSent',
        title: 'Date Form T Sent',
        type: 'text',
        required: false,
        variant: 'date',
        hint: '',
        includeUnselectedKeys: false
      }
    },
    {
      default: 12,
      xs: 12,
      field: {
        id: 'npsCallDate',
        key: 'npsCallDate',
        title: 'NPS Call Date',
        type: 'text',
        required: false,
        variant: 'date',
        hint: '',
        includeUnselectedKeys: false
      }
    },
    {
      default: 6,
      alignStart: true,
      xs: 12,
      field: {
        id: 'specialInstructions',
        title: 'Special Instructions',
        key: 'specialInstructions',
        type: 'table-input',
        tableFields: [
          {
            title: 'Special Instruction',
            id: 'specialInstruction',
            key: 'key',
            type: 'datalist-text',
            required: false,
            options: instructionAppChoices
          }
        ],
        required: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: false,
        toBeUpdated: true
      }
    },

    {
      default: 6,
      alignStart: true,
      xs: 12,
      field: {
        id: 'optionalEquipmentAndAccessories',
        title: 'Optional Equipment And Accessories',
        key: 'optionalEquipmentAndAccessories',
        type: 'table-input',
        tableFields: [
          { title: 'Optional Equipment And Accessories', key: 'key', type: 'datalist-text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'retailerOptions',
        title: 'Retailer Options',
        key: 'retailerOptions',
        type: 'table-input',
        tableFields: [
          { title: 'Retailer Option', key: 'key', type: 'datalist-text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        disabled: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: false
      }
    },
    {
      default: 6,
      xs: 12,
      alignStart: true,
      field: {
        id: 'countryPlaceFhaPaImprovements',
        title: 'Country Place (FHA) PA Improvements',
        key: 'countryPlaceFhaPaImprovements',
        type: 'table-input',
        tableFields: [
          { title: 'Option', key: 'key', type: 'datalist-text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: true
      }
    },
    {
      default: 6,
      alignStart: true,
      xs: 12,
      field: {
        id: 'twentyfirstImprovementFundsOptions',
        title: '21st Improvement Funds Options',
        key: 'twentyfirstImprovementFundsOptions',
        type: 'table-input',
        tableFields: [
          { title: '21st Improvement Funds Options', key: 'key', type: 'text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: false,
        alignStart: true
      }
    },
    {
      default: 6,
      xs: 12,
      alignStart: true,
      field: {
        id: 'tinyHomeAdditionalProducts',
        title: 'Tiny Home Additional Products',
        key: 'tinyHomeAdditionalProducts',
        type: 'key-value',
        required: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: true,
        alignStart: true
      }
    },
    {
      default: 6,
      xs: 12,
      alignStart: true,
      field: {
        id: 'twentifirstImprovementInspectionOptions',
        title: '21st Improvement Inspection Options',
        key: 'twentifirstImprovementInspectionOptions',
        type: 'table-input',
        tableFields: [
          { title: '21st Improvement Inspection Options', key: 'key', type: 'text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: false,
        alignStart: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'sellerPaidOptions',
        title: 'Seller Paid Options',
        key: 'sellerPaidOptions',
        type: 'table-input',
        tableFields: [{ title: 'Seller Paid Options', key: 'key', type: 'datalist-text', required: false }],
        required: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: false,
        toBeUpdated: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'promiseListItems',
        title: 'Promise List Items',
        key: 'promiseListItems',
        type: 'table-input',
        tableFields: [{ title: 'Promise List Items', key: 'key', type: 'datalist-text', required: false }],
        required: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: false,
        toBeUpdated: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'lender',
        key: 'lender',
        title: 'Lender',
        type: 'dropdown',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'insuranceCompany',
        key: 'insuranceCompany',
        title: 'Insurance Company',
        type: 'dropdown',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateRebateRequested',
        title: 'Date Rebate Requested',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateRebateReceived',
        title: 'Date Rebate Received',
        type: 'text',
        required: false,
        variant: 'date',
        hint: '',
        includeUnselectedKeys: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'amountRebateReceived',
        title: 'Rebate Amount Received',
        value: '',
        type: 'number',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateACRebateRequested',
        title: 'Date AC Rebate Requested',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateACRebateReceived',
        title: 'Date AC Rebate Received',
        type: 'text',
        required: false,
        variant: 'date',
        hint: '',
        includeUnselectedKeys: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'amountACRebateReceived',
        title: 'AC Rebate Amount Received',
        value: '',
        type: 'number',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'stockOrRso',
        title: 'Stock / RSO',
        type: 'dropdown',
        options: ['Stock', 'RSO'],
        required: true
      }
    },

    {
      default: 6,
      xs: 12,
      field: {
        id: 'salesTax',
        title: 'Sales Tax',
        type: 'text',
        required: false
      }
    },

    {
      default: 6,
      xs: 12,
      field: {
        id: 'originationPoints',
        title: 'Origination Points',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'applicationNumber',
        key: 'applicationNumber',
        title: 'Application Number',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'downPayment',
        title: 'Down Payment',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'cashAsAgreed',
        title: 'Cash As Agreed',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateSigned',
        title: 'Date Signed',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'anticipatedClosingDate',
        title: 'Anticipated Closing Date',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'homeWarranty',
        title: 'Home Warranty',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'driveway',
        title: 'Driveway',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'tyresAndAlxes',
        title: 'Tires / Axles',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'tradeInAmount',
        title: 'Trade-In',
        type: 'number',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'tradeInNewOrUsed',
        title: 'Trade-in New / Used',
        type: 'dropdown',
        options: ['New', 'Used'],
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'tradeInYear',
        title: 'Trade-In Year',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'tradeInBnB',
        title: 'Trade-in B/B',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'tradeInMfgPlantNumber',
        title: 'Trade in-Mfg Plant #',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'tradeInManufacturer',
        title: 'Trade-in Manufacturer',
        type: 'text'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'tradeInGrossAllowance',
        title: 'Trade-In Gross Allowance',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'lessBalanceDue',
        title: 'Less Balance Due',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'actualCashValue',
        title: 'Actual Cash Value',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'tradeSqFt',
        title: 'Trade (sq. ft)',
        type: 'number',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'paidBy',
        title: 'Paid By',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'amountReceived',
        title: 'Amount Received',
        type: 'number',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'walkthroughStatus',
        title: 'Walkthrough Status',
        type: 'dropdown',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'section',
        title: 'Section Type',
        options: ['Single-Section', 'Multi-Section'],
        type: 'dropdown',
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'taxFactorAdjustedSalesPrice',
        title: 'MHIT',
        type: 'number',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'constructionInterest',
        title: 'Construction Interest',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'offlinedate',
        key: 'offlineDate',
        title: 'Offline Date',
        variant: 'date',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'funded',
        key: 'funded',
        title: 'Funded',
        value: 'yes',
        type: 'dropdown',
        options: ['No', 'Yes'],
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateFunded',
        key: 'dateFunded',
        title: 'Date Funded',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'stateInspectionDeadline',
        key: 'stateInspectionDeadline',
        title: 'State Inspection Deadline',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'stateReinspectionDate',
        title: 'State Reinspection Date',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },

    {
      default: 6,
      xs: 12,
      field: {
        id: 'fundingStatus',
        key: 'fundingStatus',
        title: 'Funding Status',
        type: 'dropdown',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'acStatus',
        key: 'acStatus',
        title: 'AC Status',
        type: 'dropdown',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'serviceNeeded',
        title: 'Service Needed',
        type: 'dropdown',
        options: ['No', 'Yes'],
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'partsNeeded',
        title: 'Parts Needed',
        type: 'dropdown',
        options: ['No', 'Yes'],
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'partsOrdered',
        title: 'Parts Ordered',
        type: 'dropdown',
        options: ['No', 'Yes'],
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'serviceComplete',
        title: 'Service Complete',
        type: 'dropdown',
        options: ['No', 'Yes'],
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'urgentServiceComplete',
        title: 'Urgent Service Complete',
        type: 'dropdown',
        options: ['No', 'Yes'],
        required: true
      }
    },
    {
      default: 12,
      xs: 12,
      field: {
        id: 'actionRequired',
        title: 'Action Required',
        type: 'dropdown',
        options: ['No', 'Yes'],
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'commissionSheetOptions',
        title: 'Commission Sheet Options',
        key: 'commissionSheetOptions',
        onCheckedChange: 'onCommissionSheetOptionsChange',
        type: 'table-input',
        tableFields: [
          { title: 'Commission Sheet Option', key: 'key', type: 'datalist-text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        disabled: false,
        variant: '',
        hint: '',
        includeUnselectedKeys: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'commissionSheetLessOptions',
        title: 'Commission Sheet Less Options',
        key: 'commissionSheetLessOptions',
        onCheckedChange: 'onCommissionSheetLessOptionsChange',
        type: 'table-input',
        tableFields: [
          { title: 'Commission Sheet Less Option', key: 'key', type: 'datalist-text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        disabled: false,
        variant: '',
        hint: '',
        includeUnselectedKeys: true
      }
    },
    sectionCol('Cost Fields:'),
    ...costFields.map(f => ({
      default: 6,
      xs: 12,
      field: {
        id: f.key,
        title: f.label,
        type: 'number',
        required: false
      }
    })),
    sectionCol(''),
    ...typeOfTransactionFields(),
    sectionCol('Form T Fields'),
    ...formTFields.map(f => ({
      default: 6,
      xs: 12,
      field: {
        id: f.key,
        title: f.label,
        type: 'dropdown',
        options: f.options || ['No', 'Yes'],
        required: true
      }
    }))
  ]
};

export const pnlFieldsRow = {
  columns: [
    {
      default: 6,
      xs: 12,
      field: {
        id: 'salesPrice',
        title: 'Sales Price',
        type: 'number',
        onTextChange: 'calculateCommissionsSummary',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateOfDeposit',
        title: 'Date of Deposit',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },
    {
      default: 12,
      xs: 12,
      field: {
        id: 'commissionSheetLessOptions',
        title: 'Commission Less Options',
        key: 'commissionSheetLessOptions',
        onCheckedChange: 'onCommissionSheetLessOptionsChange',
        onChange: 'calculateCommissionsSummary',
        type: 'key-value',
        required: false,
        variant: '',
        hint: '',
        includeUnselectedKeys: true,
        defaultHideOptions: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'commissionSheetOptions',
        title: 'Commission Options',
        key: 'commissionSheetOptions',
        onCheckedChange: 'onCommissionSheetOptionsChange',
        onChange: 'calculateCommissionsSummary',
        type: 'key-value',
        required: false,
        variant: '',
        hint: '',
        includeUnselectedKeys: true,
        defaultHideOptions: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'pnlOptions',
        title: 'PnL Options',
        key: 'pnlOptions',
        onCheckedChange: 'onPnLOptionsChange',
        type: 'key-value',
        required: false,
        variant: '',
        hint: '',
        includeUnselectedKeys: true,
        defaultHideOptions: false
      }
    },
    {
      className: 'my-3',
      default: 12,
      field: {
        type: 'custom-html',
        html: commissionImportantFieldHTML
      }
    }
  ]
};

export const formJson = {
  forms: [
    {
      name: 'add',
      hideFormName: true,
      markCompulsoryFields: true,
      compact: true,
      submit: {
        name: 'Save Contract',
        show: false,
        onSubmit: 'onFormSubmit'
      },
      rows: [...mainFieldsRows(false), additionalFieldsRow]
    }
  ]
};

export const editFormJson = {
  forms: [
    {
      name: 'edit',
      hideFormName: true,
      markCompulsoryFields: true,
      compact: true,
      submit: {
        name: 'Update Contract',
        show: false,
        onSubmit: 'onEditFormSubmit'
      },
      rows: [...mainFieldsRows(true), additionalFieldsRow]
    }
  ]
};

export const pnlEditFormJson = {
  forms: [
    {
      name: 'edit',
      hideFormName: true,
      markCompulsoryFields: true,
      compact: true,
      submit: {
        name: 'Update Contract',
        show: false,
        onSubmit: 'onEditFormSubmit'
      },
      rows: [pnlFieldsRow]
    }
  ]
};

export const rebateEditFormJson = {
  forms: [
    {
      name: 'rebateEdit',
      hideFormName: true,
      markCompulsoryFields: true,
      compact: true,
      submit: {
        name: 'Update',
        show: true,
        onSubmit: 'onRebateEditFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 12,
              xs: 12,
              field: {
                id: 'amountRebateReceived',
                title: 'Rebate Amount',
                type: 'number',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 12,
              xs: 12,
              field: {
                id: 'dateRebateReceived',
                title: 'Date Rebate Received',
                type: 'text',
                variant: 'date',
                value: new Date().toISOString(),
                required: true
              }
            }
          ]
        }
      ]
    }
  ]
};

export const requestRebateFormJson = {
  forms: [
    {
      name: 'requestRebate',
      hideFormName: true,
      markCompulsoryFields: true,
      compact: true,
      submit: {
        name: 'Request',
        show: true,
        onSubmit: 'onRequestRebateFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 12,
              xs: 12,
              field: {
                id: 'dateRebateRequested',
                title: 'Rebate Request Date',
                type: 'text',
                variant: 'date',
                required: true,
                value: new Date().toISOString()
              }
            }
          ]
        }
      ]
    }
  ]
};

export const notificationFormJson = {
  forms: [
    {
      name: 'notification',
      hideFormName: true,
      markCompulsoryFields: true,
      submit: {
        name: 'Send Notification',
        show: true,
        onSubmit: 'onNotificationFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 12,
              xs: 12,
              field: {
                id: 'notificationActions',
                title: 'Actions',
                type: 'checkbox-group',
                options: [
                  'Create Calendar Event',
                  'Send Email',
                  'Send Walkthrough Report',
                  'Send Service Order Report'
                ],
                defaultValues: ['Create Calendar Event', 'Send Email'],
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 12,
              xs: 12,
              field: {
                id: 'attachmentFiles',
                title: 'Attachments',
                type: 'file',
                multiple: true,
                required: false
              }
            }
          ]
        }
      ]
    }
  ]
};

//used during setup
export const addChoicesToContractForm = ({ appChoices, form, ignoreTableFields }) => {
  const requiredChoices = [
    { appChoicesKey: 'salesLocation', formKey: 'salesLocation', isObject: true, labelKey: 'location' },
    { appChoicesKey: 'Utilities', formKey: 'utilities' },
    { appChoicesKey: 'Status', formKey: 'status' },
    { appChoicesKey: 'lender', formKey: 'lender', isObject: true, labelKey: 'lienholderCorporateName' },
    {
      appChoicesKey: 'insuranceCompanies',
      formKey: 'insuranceCompany',
      isObject: true,
      labelKey: 'lienholderCorporateName'
    },
    {
      appChoicesKey: 'members',
      formKey: 'signature',
      isObject: true,
      labelKey: 'name'
    },
    { appChoicesKey: 'installer', formKey: 'installer', isObject: true },
    { appChoicesKey: 'Bill-Back Status', formKey: 'billbackStatus' },
    { appChoicesKey: 'Walkthrough Status', formKey: 'walkthroughStatus' },
    { appChoicesKey: 'Tech Status', formKey: 'techStatus' },
    { appChoicesKey: 'Funding Status', formKey: 'fundingStatus' },
    { appChoicesKey: 'AC Status', formKey: 'acStatus' },
    { appChoicesKey: 'Setup Notification Options', formKey: 'setupNotificationOptions' },
    { appChoicesKey: 'Service Notification Options', formKey: 'serviceNotificationOptions' },
    { appChoicesKey: 'Delivery Notification Options', formKey: 'deliveryNotificationOptions' },
    { appChoicesKey: 'Makeready Notification Options', formKey: 'makereadyNotificationOptions' },
    {
      appChoicesKey: 'Retailer Options',
      formKey: 'retailerOptions',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Country Place (FHA) PA Improvements',
      formKey: 'countryPlaceFhaPaImprovements',
      formAttribute: 'value',
      type: 'table-input'
    },
    {
      appChoicesKey: '21st Improvement Funds Options',
      formKey: 'twentyfirstImprovementFundsOptions',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Optional Equipment And Accessories',
      formKey: 'optionalEquipmentAndAccessories',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Promise List Items',
      formKey: 'promiseListItems',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Seller Paid Options',
      formKey: 'sellerPaidOptions',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: '21st Improvement Inspection Options',
      formKey: 'twentifirstImprovementInspectionOptions',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Tiny Home Additional Products',
      formKey: 'tinyHomeAdditionalProducts',
      type: 'keyValue'
    },
    {
      appChoicesKey: 'Commission Options',
      formKey: 'commissionSheetOptions',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Commission Less Options',
      formKey: 'commissionSheetLessOptions',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Commission Options',
      formKey: 'pnlOptions',
      type: 'keyValue'
    }
  ];

  const additionalDocHubFieldsToTakeAppChoices = (appChoices.find(a => a.key === 'formFields')?.values || []).filter(
    v =>
      additionalDocHubfieldTypesWithDropdowns.includes(v.type) &&
      v.model === 'Contract' &&
      v.takeOptionsFrom === 'appChoice'
  );

  requiredChoices.push(
    ...additionalDocHubFieldsToTakeAppChoices.map(f => ({
      appChoicesKey: f.optionAppChoiceName,
      formKey: `${f.key}-additionalDochubField`
    }))
  );

  const user = getProfileFromLocalStorage();

  requiredChoices
    .filter(c => !ignoreTableFields || c.type !== 'table-input')
    .forEach(({ appChoicesKey, isObject, formKey, type, labelKey = 'name', formAttribute = 'options' }) => {
      const choice = appChoices.find(a => a.key === appChoicesKey);

      if (!choice) return;

      let valuesToUse = [...(choice.values || [])];

      if (formKey === 'signature' && !['super admin', 'admin'].includes(user?.role)) {
        valuesToUse = valuesToUse.filter(c => c._id === user?._id);
      }

      if (isObject) {
        setAttribute(
          form,
          formKey,
          'options',
          valuesToUse.map(c => c[labelKey])
        );
        setAttribute(
          form,
          formKey,
          'optionValues',
          valuesToUse.map(c => c._id)
        );
        return;
      }

      const options = type
        ? valuesToUse
            .map(value =>
              type === 'table-input'
                ? [typeof value === 'object' ? value.name : value, '']
                : { key: typeof value === 'object' ? value.name : value, type: 'number' }
            )
            ?.slice(0, type === 'table-input' ? 10 : valuesToUse.length)
        : valuesToUse;

      const dropdownOptions = valuesToUse
        ? valuesToUse.map(value =>
            type === 'table-input'
              ? [typeof value === 'object' ? value.name : value]
              : { key: typeof value === 'object' ? value.name : value, type: 'number' }
          )
        : valuesToUse;

      setAttribute(form, formKey, formAttribute, options, dropdownOptions);
    });

  const members = appChoices.find(c => c.key === 'members').values;

  const techFields = ['tech', 'serviceTech'];
  const techOptions = members.filter(m => m.role === 'Tech');
  techFields.forEach(f => {
    setAttribute(
      form,
      f,
      'options',
      techOptions.map(e => e.name)
    );
    setAttribute(
      form,
      f,
      'optionValues',
      techOptions.map(e => e._id)
    );
  });

  const contractors = appChoices.find(c => c.key === 'generalContractor')?.values || [];
  const emailOptions = [
    { name: 'Buyer in Contract (If available)', email: 'buyer-mhc', role: 'Other' },
    ...members,
    ...contractors.map(c => ({ ...c, role: c.category || 'Contractor', type: 'contractors' }))
  ];

  const toBeGroupedBySalesLocation = ['Super Admin', 'Admin', 'Sales', 'Sales Manager'];
  const roleOrder = [...APP_ROLES, ...CONTRACTOR_CATEGORY_OPTIONS];

  emailOptions.sort((e1, e2) => {
    const e1RoleIndex = roleOrder.indexOf(e1.role);
    const e2RoleIndex = roleOrder.indexOf(e2.role);
    const roleCompare = e1RoleIndex - e2RoleIndex;
    return roleCompare === 0 ? e1.name.localeCompare(e2.role) : roleCompare;
  });

  contractMilestoneDates.forEach(({ emailField }) => {
    setAttribute(
      form,
      emailField,
      'options',
      emailOptions.map(e => e.name)
    );
    setAttribute(
      form,
      emailField,
      'optionValues',
      emailOptions.map(e => e.email)
    );
    setAttribute(
      form,
      emailField,
      'groupValues',
      emailOptions.map(e =>
        toBeGroupedBySalesLocation.includes(e.role) ? e.salesLocation?.location || e.role : e.role
      )
    );
  });
};

//used after submit
export const validateAndCleanupForm = submittedForm => {
  if (
    (submittedForm['amountRebateReceived'] && !submittedForm['dateRebateReceived']) ||
    (submittedForm['dateRebateReceived'] && !submittedForm['amountRebateReceived'])
  ) {
    highlightError(
      document.getElementById(submittedForm['amountRebateReceived'] ? 'dateRebateReceived' : 'amountRebateReceived'),
      'Both amount and received date of the rebate are required!'
    );

    toast.error('Both amount and received date of the rebate are required!');
    return;
  }

  for (const dates of contractMilestoneDates) {
    const { key, start, end, emailField, options } = dates;
    if (submittedForm[start]) {
      //if start date is input, then emails are compulsory
      if (!submittedForm[emailField] || submittedForm[emailField].length === 0) {
        return highlightError(document.getElementById(emailField));
      }
    }

    //date is compulsory in date pairs [which has end property]
    if (end && ((submittedForm[end] && !submittedForm[start]) || (submittedForm[start] && !submittedForm[end]))) {
      return highlightError(
        document.getElementById(submittedForm[end] ? start : end),
        'Both start and end time are required'
      );
    }
    //now either we have all start and end date[if in pair] and emailField or none

    if (end && new Date(submittedForm[end]).getTime() < new Date(submittedForm[start]).getTime()) {
      return highlightError(document.getElementById(end), 'End time cannot be less than start time');
    }

    if (submittedForm.hasOwnProperty(start)) {
      submittedForm[key] = {
        start: submittedForm[start],
        notificationEmails: submittedForm[emailField]
          .map(e => (e === 'buyer-mhc' ? submittedForm['buyerEmail'] : e))
          .filter(e => Boolean(e))
      };

      if (end) {
        submittedForm[key]['end'] = submittedForm[end];
        submittedForm[key]['options'] = submittedForm[options];
      }
    }
  }

  const sizes = ['sizeA', 'sizeB', 'sizeC'];
  for (const size of sizes) {
    if (size !== 'Size A' && !submittedForm[size]) {
      continue;
    }

    const split = submittedForm[size].split('x').map(a => Number(a.trim()));
    if (split.length != 2 || split.includes(NaN)) {
      highlightError(document.getElementById(size), 'Please enter size in the format of a x b');
      return;
    }
  }

  if (submittedForm['sameMailingAddress']) {
    submittedForm['mailingAddress'] = submittedForm['propertyAddress'];
    submittedForm['mailingZip'] = submittedForm['zipCode'];
    submittedForm['mailingState'] = submittedForm['propertyState'];
    submittedForm['mailingCity'] = submittedForm['propertyCity'];
    submittedForm['mailingCounty'] = submittedForm['propertyCounty'];
  }

  if (submittedForm['tech'] === 'Select one...') {
    submittedForm['tech'] = '';
  }

  if (submittedForm['serviceTech'] === 'Select one...') {
    submittedForm['serviceTech'] = '';
  }

  convertFormToKeyValueObject(submittedForm);

  if (submittedForm['typeOfTransaction']) {
    if (submittedForm['typeOfTransaction'] === 'Select One...') {
      submittedForm['typeOfTransaction'] = '';
    } else if (submittedForm['typeOfTransaction'].includes('Other')) {
      submittedForm['typeOfTransaction'] = submittedForm['typeOfTransaction'].replace(
        'Other',
        submittedForm['otherType']
      );
    }
  }

  //for additional dochub fields created by user
  const additionalFields = Object.keys(submittedForm).filter(k => k.endsWith('-additionalDochubField'));
  submittedForm['additionalFields'] = additionalFields.reduce(
    (acc, key) => ({ ...acc, [key.replace('-additionalDochubField', '')]: submittedForm[key] }),
    {}
  );
  return true;
};

//used after submit
export const validateAndCleanupQuickEditForm = submittedForm => {
  if (
    (submittedForm['amountRebateReceived'] && !submittedForm['dateRebateReceived']) ||
    (submittedForm['dateRebateReceived'] && !submittedForm['amountRebateReceived'])
  ) {
    toast.error('Both amount and received date of the rebate are required!');
    return;
  }

  for (const dates of contractMilestoneDates) {
    const { key } = dates;
    if (!submittedForm[key]) continue;

    //date is compulsory in date pairs [which has end property]
    if (
      (submittedForm[key]['end'] && !submittedForm[key]['start']) ||
      (submittedForm[key]['start'] && !submittedForm[key]['end'])
    ) {
      toast.error('Both start and end time are required');
      return;
    }
    //now either we have all start and end date[if in pair] and emailField or none

    if (new Date(submittedForm[key]['end']).getTime() < new Date(submittedForm[key]['start']).getTime()) {
      toast.error('End time cannot be less than start time');
      return;
    }
  }

  return true;
};

import React, { useMemo, useState } from 'react';
import { Badge, FormControl } from 'react-bootstrap';

const dividedRows = (tabs, numOfRows) => {
  if (numOfRows === 1) return [tabs];

  const tempTabs = [...tabs];
  let result = [];
  for (let i = numOfRows; i > 0; i--) {
    result.push(tempTabs.splice(0, Math.ceil(tempTabs.length / i)));
  }
  return result;
};

const Tabs = ({ tabs = [], onTabSelect, activeTab, scrollable, numOfScrollableRows = 1 }) => {
  const [query, setQuery] = useState('');

  const filteredTabs = useMemo(() => {
    if (!query.trim()) {
      return [...tabs];
    }

    return tabs.filter(t => t.toLowerCase().includes(query.trim().toLowerCase()));
  }, [query, tabs]);

  return (
    <>
      <div>
        <FormControl
          className="w-50"
          size="sm"
          placeholder="Search choices..."
          value={query}
          onChange={e => setQuery(e.target.value)}
        />
      </div>
      {scrollable ? (
        <div style={{ whiteSpace: 'nowrap', overflowX: 'scroll' }} className="my-2">
          {dividedRows(filteredTabs, numOfScrollableRows).map(row => (
            <div className="mb-1">
              {row.map(t => (
                <Badge
                  className="hover-dark p-2 mr-1"
                  onClick={() => onTabSelect(t)}
                  key={t}
                  variant={activeTab === t ? 'info' : 'primary'}
                >
                  {t}
                </Badge>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="my-2">
          {filteredTabs.map(t => (
            <Badge
              className="hover-dark p-2 mr-1"
              onClick={() => onTabSelect(t)}
              key={t}
              variant={activeTab === t ? 'info' : 'primary'}
            >
              {t}
            </Badge>
          ))}
        </div>
      )}
    </>
  );
};

export default Tabs;

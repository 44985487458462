import {
  DrawingManager,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  OverlayView,
  Polygon
} from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { ArrowsMove, Building, Person } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import './GoogleMap.css';
import MarkerIcon from './Marker.png';

const libraries = ['drawing'];

const containerStyle = {
  width: '100%',
  height: '100%'
};

const defaultPolygonOptions = {
  strokeColor: '#FFFF00',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FFFF00',
  fillOpacity: 0.35,
  editable: false,
  draggable: false
};

const selectedPolygonOptions = {
  strokeColor: '#00FF00',
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: '#00FF00',
  fillOpacity: 0.8,
  editable: true,
  draggable: true
};

const dimmedPolygonOptions = {
  strokeColor: '#A9A9A9',
  fillColor: '#A9A9A9',
  fillOpacity: 0.5
};

/**
 * 
 * Empty Slot: Grayish
Stock Unit: Green
Contracted: Red
 */

const emptySlotColorOptions = { strokeColor: '#f4ffe6', fillColor: '#f4ffe6' };
const occupiedContractedSlots = { strokeColor: '#dc3545', fillColor: '#dc3545' };
const occupiedInStockSlots = { strokeColor: '#28a745', fillColor: '#28a745' };
const closedSlotColorOptions = { strokeColor: 'black', fillColor: '#FF6347' };
const officeBuildingSlot = { strokeColor: 'yellow', fillColor: 'yellow' };

const getCurtailmentCutOffDate = () => {
  const today = new Date();

  // Set year to previous year
  today.setFullYear(today.getFullYear() - 1);

  // Set to the next month
  today.setMonth(today.getMonth() + 1);

  // Set to the last day of the intended month
  today.setDate(0);

  // Set the time to just before midnight
  today.setHours(23, 59, 59, 999);

  return today.getTime();
};

function Map({
  center,
  zoom = 12,
  rotation = 0,
  polygons = [],
  onAddNewPolygon,
  onPolygonUpdate,
  disableDrawing,
  infoWindowMeta = {},
  setInfoWindowMeta,
  handlePolygonClick,
  InfoWindowContent,
  onMapPropertiesChange
}) {
  const [internalPolygons, setInternalPolygons] = useState([]);
  const [map, setMap] = useState(null);
  const [drawingOptions, setDrawingOptions] = useState(null);
  const [isRepositioning, setIsRepositioning] = useState(false);
  const [selectedPolygonIndex, setSelectedPolygonIndex] = useState(null);
  const polygonRefs = useRef([]);
  const [currentRotation, setCurrentRotation] = useState(0);
  const [currentScale, setCurrentScale] = useState(1);
  const [liveRotation, setLiveRotation] = useState(0);
  const [liveScale, setLiveScale] = useState(1);
  const originalPolygon = useRef(null); // Store the original unmodified polygon
  const [role, setRole] = useState(localStorage.getItem('user-role'));
  const [drawingManager, setDrawingManager] = useState();
  const curtailmentCutOffDate = useMemo(() => getCurtailmentCutOffDate(), []);

  const handleMouseDownForRectangle = event => {
    if (drawingManager?.getDrawingMode() !== window.google.maps.drawing.OverlayType.RECTANGLE) {
      // Exit if drawing mode is not rectangle
      return;
    }

    const clickPosition = event.latLng;

    // The factor can be adjusted as needed to fit your requirements
    const sizeFactor = 0.0001;

    const NE = new window.google.maps.LatLng(clickPosition.lat() + sizeFactor, clickPosition.lng() + sizeFactor);
    const SW = new window.google.maps.LatLng(clickPosition.lat() - sizeFactor, clickPosition.lng() - sizeFactor);

    const bounds = new window.google.maps.LatLngBounds(SW, NE);

    const rectangle = new window.google.maps.Rectangle({
      bounds: bounds,
      map: map,
      ...defaultPolygonOptions
    });

    handlerRectangleComplete(rectangle);
  };

  useEffect(() => {
    if (map && drawingManager) {
      // Clear existing 'mousedown' listeners to prevent duplicates
      window.google.maps.event.clearListeners(map, 'mousedown');

      // Add the new 'mousedown' listener for the rectangle drawing
      map.addListener('mousedown', handleMouseDownForRectangle);
    }

    // Cleanup function to remove the listener when the component unmounts or dependencies change
    return () => {
      if (map) {
        window.google.maps.event.clearListeners(map, 'mousedown');
      }
    };
  }, [map, drawingManager]);

  // To store the modified polygon during transformations
  const modifiedPolygon = useRef(null);

  useEffect(() => {
    setInternalPolygons(polygons || []);
  }, [polygons]);

  const onLoad = useCallback(
    mapInstance => {
      setMap(mapInstance);

      // Trigger the onMapPropertiesChange callback when map center, zoom, or bounds change
      mapInstance.addListener('center_changed', () => {
        const center = mapInstance.getCenter();
        const zoom = mapInstance.getZoom();
        const bounds = mapInstance.getBounds();
        onMapPropertiesChange({ center: center.toJSON(), zoom, bounds: bounds?.toJSON() });
      });

      mapInstance.addListener('zoom_changed', () => {
        const center = mapInstance.getCenter();
        const zoom = mapInstance.getZoom();
        const bounds = mapInstance.getBounds();
        onMapPropertiesChange({ center: center.toJSON(), zoom, bounds: bounds?.toJSON() });
      });

      mapInstance.addListener('bounds_changed', () => {
        const center = mapInstance.getCenter();
        const zoom = mapInstance.getZoom();
        const bounds = mapInstance.getBounds();
        onMapPropertiesChange({ center: center.toJSON(), zoom, bounds: bounds?.toJSON() });
      });

      setDrawingOptions({
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            window.google.maps.drawing.OverlayType.POLYGON,
            window.google.maps.drawing.OverlayType.RECTANGLE
          ]
        },
        polygonOptions: defaultPolygonOptions,
        rectangleOptions: defaultPolygonOptions
      });
    },
    [onMapPropertiesChange]
  );

  const onUnmount = useCallback(() => setMap(null), []);

  const handlePolygonComplete = polygon => {
    const paths = polygon
      .getPath()
      .getArray()
      .map(latlng => ({
        lat: latlng.lat(),
        lng: latlng.lng()
      }));

    if (paths.length < 4) {
      toast.error('A slot must have atleast four vertex');
      polygon.setMap(null);
      return;
    }

    onAddNewPolygon(paths);
    polygon.setMap(null);
  };

  const handlerRectangleComplete = rectangle => {
    const bounds = rectangle.getBounds();
    const NE = bounds.getNorthEast(); // Top-right corner
    const SW = bounds.getSouthWest(); // Bottom-left corner

    const paths = [
      { lat: NE.lat(), lng: NE.lng() }, // Top-right
      { lat: NE.lat(), lng: SW.lng() }, // Top-left
      { lat: SW.lat(), lng: SW.lng() }, // Bottom-left
      { lat: SW.lat(), lng: NE.lng() } // Bottom-right
    ];

    onAddNewPolygon(paths);
    rectangle.setMap(null); // Optionally remove the rectangle from the map
  };

  const handlePolygonClickInternal = (index, event) => {
    if (!isRepositioning) {
      handlePolygonClick(index, event);
      setSelectedPolygonIndex(index);
      originalPolygon.current = [...internalPolygons[index].paths]; // Store original state
      modifiedPolygon.current = [...internalPolygons[index].paths]; // Store initial to-be-modified state
      setCurrentRotation(0);
      setCurrentScale(1);
    }
  };

  const handleRepositionClick = () => {
    setIsRepositioning(true);
    setInfoWindowMeta(null); // Hide popup
  };

  const handleDoneClick = () => {
    if (modifiedPolygon.current && selectedPolygonIndex !== null) {
      const newPolygon = {
        ...internalPolygons[selectedPolygonIndex],
        paths: rotateAndScalePolygon(originalPolygon.current, liveRotation, liveScale) // Always start from original state
      };
      onPolygonUpdate(newPolygon); // Only make one API call here
      setIsRepositioning(false);
      setLiveRotation(0);
      setLiveScale(1);
      setCurrentRotation(0);
      setCurrentScale(1);
      setInfoWindowMeta({ polygonIndex: selectedPolygonIndex });
    }
  };

  const handleCloseInfoWindow = () => {
    setSelectedPolygonIndex(null);
    setInfoWindowMeta(null);
    setIsRepositioning(false);
  };

  const calculateCenter = paths => {
    if (!window.google) return null;

    const bounds = new window.google.maps.LatLngBounds();
    paths.forEach(path => {
      bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
    });
    return bounds.getCenter().toJSON();
  };

  const calculateLeftTopCorner = paths => {
    if (!paths || paths.length === 0) return null;

    // Start with the first point as a candidate
    let leftTopPoint = paths[0];

    paths.forEach(path => {
      // Compare to find the leftmost and topmost point
      if (path.lng < leftTopPoint.lng || (path.lng === leftTopPoint.lng && path.lat > leftTopPoint.lat)) {
        leftTopPoint = path;
      }
    });

    return leftTopPoint;
  };

  const rotatePolygon = (angle, paths) => {
    if (!paths || paths.length === 0) return paths;

    const center = calculateCenter(paths);

    if (!center) return paths;

    const rad = (angle * Math.PI) / 180;

    return paths.map(({ lat, lng }) => {
      if (lat == null || lng == null) return { lat, lng };

      const x = lng - center.lng;
      const y = lat - center.lat;
      return {
        lat: center.lat + (x * Math.sin(rad) + y * Math.cos(rad)),
        lng: center.lng + (x * Math.cos(rad) - y * Math.sin(rad))
      };
    });
  };

  const scalePolygon = (factor, paths) => {
    if (!paths || paths.length === 0) return paths;

    const center = calculateCenter(paths);

    if (!center) return paths;

    return paths.map(({ lat, lng }) => {
      if (lat == null || lng == null) return { lat, lng };

      const x = lng - center.lng;
      const y = lat - center.lat;
      return {
        lat: center.lat + y * factor,
        lng: center.lng + x * factor
      };
    });
  };

  // Recalculate all transformations from scratch
  const applyTransformationsFromOriginal = (paths, rotation, scale) => {
    const rotatedPaths = rotatePolygon(rotation, paths);
    const scaledPaths = scalePolygon(scale, rotatedPaths);
    return scaledPaths;
  };

  const rotateAndScalePolygon = (paths, angle, factor) => {
    const scaledPaths = scalePolygon(factor, paths); // First apply scaling
    const rotatedPaths = rotatePolygon(angle, scaledPaths); // Then apply rotation
    return rotatedPaths;
  };

  const handleRotationChange = angle => {
    setCurrentRotation(angle);
    setLiveRotation(angle);
  };

  const handleScaleChange = factor => {
    setCurrentScale(factor);
    setLiveScale(factor);
  };

  // Smooth real-time update for sliders without cumulative transformations
  useEffect(() => {
    const interval = setInterval(() => {
      if (isRepositioning && selectedPolygonIndex !== null) {
        // Always start from the original polygon, then apply transformations
        const transformedPolygon = applyTransformationsFromOriginal(originalPolygon.current, liveRotation, liveScale);
        modifiedPolygon.current = transformedPolygon;
      }
    }, 10); // Update every 10ms

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [liveRotation, liveScale, isRepositioning, selectedPolygonIndex]);

  const resetSliders = () => {
    setCurrentRotation(0);
    setCurrentScale(1);
  };

  const updatePolygonFromDragging = (polygon, index) => {
    const paths = polygon
      .getPath()
      .getArray()
      .map(latlng => ({
        lat: latlng.lat(),
        lng: latlng.lng()
      }));

    // Update the local modifiedPolygon reference (no API call)
    modifiedPolygon.current = paths;
    originalPolygon.current = paths; // Persist dragging changes to originalPolygon
  };

  return (
    <>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY} libraries={libraries}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          heading={rotation}
          onLoad={onLoad}
          onUnmount={onUnmount}
          tilt={0}
          mapTypeId="satellite"
          onClick={handleCloseInfoWindow}
        >
          {internalPolygons?.length > 0 &&
            internalPolygons.map((polygon, index) => (
              <React.Fragment key={polygon._id}>
                <Polygon
                  paths={
                    index === selectedPolygonIndex && isRepositioning
                      ? rotateAndScalePolygon(modifiedPolygon.current, liveRotation, liveScale)
                      : polygon.paths
                  }
                  onLoad={ref => (polygonRefs.current[index] = ref)}
                  onClick={event => handlePolygonClickInternal(index, event)}
                  options={
                    index === selectedPolygonIndex && isRepositioning
                      ? selectedPolygonOptions
                      : index === selectedPolygonIndex
                      ? { ...defaultPolygonOptions }
                      : isRepositioning
                      ? dimmedPolygonOptions
                      : {
                          ...defaultPolygonOptions,
                          ...(polygon.status === 'Closed'
                            ? closedSlotColorOptions
                            : polygon.inventory && polygon.inventory.contract
                            ? occupiedContractedSlots
                            : polygon.inventory
                            ? occupiedInStockSlots
                            : polygon.isOfficeBuilding
                            ? officeBuildingSlot
                            : emptySlotColorOptions)
                        }
                  }
                  editable={index === selectedPolygonIndex && isRepositioning}
                  draggable={index === selectedPolygonIndex && isRepositioning}
                  onMouseUp={event => {
                    if (index === selectedPolygonIndex && isRepositioning) {
                      updatePolygonFromDragging(polygonRefs.current[index], index); // Update modified polygon on drag
                    }
                  }}
                />
                {!isRepositioning && (
                  <>
                    <OverlayView
                      position={calculateCenter(polygon?.paths)}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div className="w-100">
                        {polygon?.isOfficeBuilding && (
                          <Marker
                            title="Office Building"
                            icon={{
                              url: 'https://cdn-icons-png.flaticon.com/128/2776/2776067.png',
                              path: MarkerIcon
                            }}
                            position={calculateCenter(polygon?.paths)}
                          />
                        )}

                        {polygon?.inventory && (
                          <div className="text-white mb-2">
                            {[
                              { text: polygon?.inventory?.serialA, Icon: Building },
                              { text: polygon?.inventory?.contract?.buyer, Icon: Person }
                              /* { text: polygon?.inventory?.sizeA, Icon: BoundingBox },
                              { text: polygon?.inventory?.manufacturer?.name, Icon: GearFill } */
                            ]
                              .filter(item => item.text)
                              .map(({ text, Icon }) => (
                                <span
                                  style={{ width: 100, overflowWrap: 'break-word' }}
                                  className="d-flex font-weight-bold text-white align-items-center tinyFont "
                                >
                                  <Icon size={12} />
                                  <span className="mx-1"> {text || 'N/A'}</span>
                                </span>
                              ))}
                            {polygon?.inventory?.invoiceDate &&
                              new Date(polygon?.inventory?.invoiceDate).getTime() < curtailmentCutOffDate && (
                                <Badge style={{ fontSize: 8 }} variant="warning" className="px-1 tinyFont">
                                  <span>Curtailment</span>
                                </Badge>
                              )}
                          </div>
                        )}
                      </div>
                    </OverlayView>
                    <OverlayView
                      position={calculateLeftTopCorner(polygon?.paths)}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div className="p-1">
                        {polygon?.number && (
                          <Badge className="px-1" variant="dark">
                            #{polygon?.number}
                          </Badge>
                        )}
                      </div>
                    </OverlayView>
                  </>
                )}
              </React.Fragment>
            ))}

          {drawingOptions && !disableDrawing && ['admin', 'super admin'].includes(role) && (
            <DrawingManager
              onLoad={drawingManager => setDrawingManager(drawingManager)}
              onRectangleComplete={handlerRectangleComplete}
              onPolygonComplete={handlePolygonComplete}
              options={drawingOptions}
            />
          )}

          {infoWindowMeta?.polygonIndex !== undefined && infoWindowMeta?.polygonIndex !== null && (
            <InfoWindow
              position={calculateCenter(internalPolygons[infoWindowMeta.polygonIndex]?.paths)}
              onCloseClick={handleCloseInfoWindow}
            >
              <div>
                {InfoWindowContent()}

                {['admin', 'super admin'].includes(role) && (
                  <Button
                    variant="primary"
                    style={{
                      position: 'absolute',
                      top: '10px',
                      left: '10px',
                      padding: '4px 8px',
                      fontSize: '0.75rem'
                    }}
                    onClick={handleRepositionClick}
                  >
                    <ArrowsMove style={{ fontSize: '1rem' }} />
                    Transform
                  </Button>
                )}
              </div>
            </InfoWindow>
          )}

          {isRepositioning && selectedPolygonIndex !== null && (
            <div className="floating-transformation-menu">
              <h5>Transform Polygon</h5>
              <label>Rotate: {currentRotation}°</label>
              <input
                type="range"
                value={currentRotation}
                min={-180}
                max={180}
                onChange={e => handleRotationChange(parseFloat(e.target.value))}
              />
              <br />
              <label>Scale: {currentScale}x</label>
              <input
                type="range"
                value={currentScale}
                min={0.25}
                max={4}
                step={0.01}
                onChange={e => handleScaleChange(parseFloat(e.target.value))}
              />
              <Button variant="primary" onClick={handleDoneClick}>
                Finish
              </Button>
            </div>
          )}
        </GoogleMap>
      </LoadScript>
    </>
  );
}

export default Map;
